const axios = require('axios')
const api_base = process.env.VUE_APP_API_BASE_URL

const getVisitorFee = async (parking_id) => {
    const url = `${api_base}/v1/fees/visitor/${parking_id}`

    return await axios.get(url)
}

const getMotorcycleFee = async (parking_id) => {
    const url = `${api_base}/v1/fees/motorcycle/${parking_id}`

    return await axios.get(url)
}

const createVisitorFee = async (fee) => {
    const url = `${api_base}/v1/fees/visitor`

    return await post(url, fee)
}

const createMotorcycleFee = async (fee) => {
    const url = `${api_base}/v1/fees/motorcycle`

    return await post(url, fee)
}

const simulateVisitorFee = async () => {

}

//dE4ASAQiGw8YRLr8uxr

const getRegisteredFee = async (parking_id, type) => {
    const sType = (type === 1) ? 'MENSUAL': 'DIARIA'
    const url = `${api_base}/v1/fees/subscription/${parking_id}/${sType}`

    return  await axios.get(url)
}

const createRegisteredFee = async(registeredFee) => {
    const url = `${api_base}/v1/fees/subscription`

    return await post(url, {
        parking_id: registeredFee.parking_id,
        type: (registeredFee.type === 1) ? 'MENSUAL': 'DIARIA',
        fee: registeredFee.fee
    })
}

const getSpecialFees = async (parking_id) => {
    const url = `${api_base}/v1/fees/special/${parking_id}`

    const data = await axios.get(url)

    return data.data.fees
}

const getSpecialFee = async (fee_id, parking_id) => {
    const url = `${api_base}/v1/fees/special/${parking_id}/${fee_id}`

    const data = await axios.get(url)

    return data.data.fee
}

const createSpecialFee = async (fee_payload) => {
    const url = `${api_base}/v1/fees/special`

    return await  post(url, {
        parking_id: fee_payload.parking_id,
        fee: fee_payload.fee,
        name: fee_payload.name,
        active: fee_payload.access,
        type: fee_payload.type,
        _id: fee_payload._id
    })
}

const deleteSpecialFee = async (fee_id, parking_id) => {
    const url = `${api_base}/v1/fees/special`

    return await del(url, {
        data: {
            fee_id,
            parking_id
        }
    }).catch((error) => {
        console.log('ERROR DELETING SPECIAL FEE')
        const retError = {
            errors: error.response.data
        }

        retError.status = error.response.status

        return retError
    })
}

const del = async (url, data) => {
    return await axios.delete(url, data)
        .catch((error) => {
            const retError = {
                errors: error.response.data
            }

            retError.status = error.response.status

            return retError
        })
}

const post = async (url, data) => {
    return await axios.post(url, data)
        .catch((error) => {
            const retError = {
                errors: error.response.data
            }

            retError.status = error.response.status

            return retError
        })
}

module.exports = {
    createVisitorFee,
    simulateVisitorFee,
    getVisitorFee,
    getRegisteredFee,
    createRegisteredFee,
    createMotorcycleFee,
    getMotorcycleFee,
    getSpecialFees,
    createSpecialFee,
    deleteSpecialFee,
    getSpecialFee
}