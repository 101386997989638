
<script>
import Layout from "../../layouts/main"
import PageHeader from "@/components/page-header"
import Swal from "sweetalert2"

import {getUserParkings} from '@/services/parkings.service'
import {
    createRegisteredFee,
    createVisitorFee,
    getRegisteredFee,
    getVisitorFee,
    createMotorcycleFee,
    getMotorcycleFee,
    getSpecialFees,
    createSpecialFee,
    deleteSpecialFee,
    getSpecialFee
} from '@/services/fees.service'
import {required} from "vuelidate/lib/validators";

export default {
    components: {
        Layout,
        PageHeader
    },
    data() {
        return {
            title: "Tarifas",
            items: [
                {
                    text: "MaxParking"
                },
                {
                    text: "Tarifas",
                    active: true
                }
            ],
            registered_vehicle_fee_types: [
                {
                    value: 1,
                    text: "Mensual"
                },
                {
                    value: 2,
                    text: "Diaria"
                }
            ],
            temp_assigned_parklot: 0,
            temp_m_assigned_parklot: 0,
            registered_assigned_parklot: 0,
            parkingOptions: [],
            selected_visitor_fee: {
              parking_id: "",
              free_duration: "",
              initial_fee: "",
              initial_fee_duration: "",
              repeat_fee: "",
              repeat_fee_duration: "",
              max_fee_enabled: null,
              max_fee: "",
            },
            selected_motorcycle_fee: {
              parking_id: "",
              free_duration: "",
              initial_fee: "",
              initial_fee_duration: "",
              repeat_fee: "",
              repeat_fee_duration: "",
              max_fee_enabled: null,
              max_fee: "",
            },
            selected_registered_fee: {
              parking_id: null,
              type: null,
              fee: null
            },
          special_fee: {
            _id: null,
            parking_id: "",
            type: null,
            active: true,
            fee: 0.00,
            name: ""
          },
          special_fees: [],
          show_specialFeeModal: false,
          show_specialFeeEditModal: false,
          isEditSFError: false,
          editSFError: "",
          edit_submitted: false,
          isCreateSFError: false,
          createSFError: "",
          submitted: false,
        }
    },
    async mounted() {
        this.userParkings = await getUserParkings()
        this.parkingOptions = this.getParkingOptions()
        for(const parking of this.userParkings) {
          const parking_fees = await getSpecialFees(parking._id)
          this.special_fees = [...this.special_fees, ...parking_fees]
        }
    },
    validations: {
      special_fee: {
        parking_id: { required },
        fee: { required },
        name: { required },
      }
    },
    methods: {
      getParkingName(id) {
            const parking = this.userParkings.filter(parking => parking._id === id)
            return parking[0].name
        },
      getParkingOptions() {
          return this.userParkings.map((parking) => {
              return {
                value: parking._id,
                text: parking.name
              }
            })
        },
      async getVisitorFee() {
          const visitorFee = await getVisitorFee(this.temp_assigned_parklot)
          this.selected_visitor_fee = {}
          if(visitorFee.data.fee) {
            this.selected_visitor_fee = visitorFee.data.fee
          }
        },
      async getMotorcycleFee() {
          const visitorFee = await getMotorcycleFee(this.temp_m_assigned_parklot)
          console.log(visitorFee)
          this.selected_motorcycle_fee = {}
          if(visitorFee.data.fee) {
            this.selected_motorcycle_fee = visitorFee.data.fee
          }
        },
      async saveVisitorFee() {
          this.selected_visitor_fee.parking_id = this.temp_assigned_parklot
          const result = await createVisitorFee(this.selected_visitor_fee)

          if (result.status === 201) {
            await Swal.fire('Éxito', 'Tarifa de visitante creada/actualizada exitosamente', 'success')
          } else if (result.status >= 400 && result.status < 500) {
            let message = ''
            Object.keys(result.errors).forEach(e => {
                  message += `${result.errors[e][0]}<br/>`
                }
            )

            await Swal.fire('Error', message, 'error')
          }
        },
      async saveMotorcycleFee() {
          this.selected_motorcycle_fee.parking_id = this.temp_m_assigned_parklot
          const result = await createMotorcycleFee(this.selected_motorcycle_fee)

          if (result.status === 201) {
            await Swal.fire('Éxito', 'Tarifa de motocicleta creada/actualizada exitosamente', 'success')
          } else if (result.status >= 400 && result.status < 500) {
            let message = ''
            Object.keys(result.errors).forEach(e => {
                  message += `${result.errors[e][0]}<br/>`
                }
            )

            await Swal.fire('Error', message, 'error')
          }
        },
      async getRegisteredFee() {
          let registeredFee = await getRegisteredFee(this.selected_registered_fee.parking_id, this.selected_registered_fee.type)

          registeredFee = registeredFee.data.fee

          if (!registeredFee) {
            this.selected_registered_fee.fee = 0
          }

          this.selected_registered_fee = {
            type: (registeredFee.type === 'MENSUAL') ? 1: 2,
            fee: registeredFee.fee,
            parking_id: registeredFee.parking_id,
          }
        },
      async saveRegisteredFee() {
          const result = await createRegisteredFee(this.selected_registered_fee)
          if (result.status === 201) {
            await Swal.fire('Éxito', 'Tarifa de registro ha sido creada/actualizada exitosamente', 'success')
          } else if (result.status >= 400 && result.status < 500) {
            let message = ''
            console.log(result)
            Object.keys(result.errors).forEach(e => {
                  message += `${result.errors[e][0]}<br/>`
                }
            )

            await Swal.fire('Error', message, 'error')
          }
        },
      async createSpecialFee() {
          this.submitted = true
          this.isCreateSFError = false
          this.createSFError = ""
          this.$v.$touch()

          if (this.$v.$invalid) {
            console.log('invalid')
          } else  {
            const result = await createSpecialFee(this.special_fee)

            if (result.status === 201) {
              await Swal.fire('Éxito', 'Tarifa especial ha sido creada exitosamente', 'success')
              this.hideModal()
              this.special_fees = []
              for(const parking of this.userParkings) {
                const parking_fees = await getSpecialFees(parking._id)
                this.special_fees = [...this.special_fees, ...parking_fees]
              }
            } else if (result.status >= 400 && result.status < 500) {
              let message = ''
              Object.keys(result.errors).forEach(e => {
                    message += `${result.errors[e][0]}<br/>`
                  }
              )

              await Swal.fire('Error', message, 'error')
            }
          }
        },
      async editSpecialFee() {
        this.edit_submitted = true
        this.isEditSFError = false
        this.editSFError = ""
        this.$v.$touch()

        if (this.$v.$invalid) {
          console.log('invalid')
        } else  {
          const result = await createSpecialFee(this.special_fee)

          if (result.status === 201) {
            await Swal.fire('Éxito', 'Tarifa especial ha sido editada exitosamente', 'success')
            this.hideEditSFModal()
            this.special_fees = []
            for(const parking of this.userParkings) {
              const parking_fees = await getSpecialFees(parking._id)
              this.special_fees = [...this.special_fees, ...parking_fees]
            }
          } else if (result.status >= 400 && result.status < 500) {
            let message = ''
            Object.keys(result.errors).forEach(e => {
                  message += `${result.errors[e][0]}<br/>`
                }
            )

            await Swal.fire('Error', message, 'error')
          }
        }
      },
      async loadEditSpecialFee(fee_id, parking_id) {
          const specialFee = await getSpecialFee(parking_id, fee_id)
          this.special_fee = specialFee

          this.show_specialFeeEditModal = true
      },
      async delSpecialFee(fee_id, parking_id) {
        Swal.fire({
          title: "¿Esta seguro?",
          text: "Borrar una tarifa especial no es reversible.",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#34c38f",
          cancelButtonColor: "#f46a6a",
          confirmButtonText: "Si, borrar!",
          cancelButtonText: "Cancelar"
        }).then(async (result) => {
          if (result.value) {
            await deleteSpecialFee(fee_id, parking_id)

            this.special_fees = []
            for(const parking of this.userParkings) {
              const parking_fees = await getSpecialFees(parking._id)
              this.special_fees = [...this.special_fees, ...parking_fees]
            }

            Swal.fire("Borrado!", "Tarifa especial eliminada.", "success");
          }
        })
      },
      hideModal() {
        this.special_fee = {
          parking_id: "",
          type: "",
          active: true,
          fee: 0.00,
          name: ""
        }
        this.submitted = false
        this.show_specialFeeModal = false
        this.isCreateSFError = false
        this.createSFError = ""
      },
      hideEditSFModal() {
        this.special_fee = {
          parking_id: "",
          type: "",
          active: true,
          fee: 0.00,
          name: ""
        }
        this.edit_submitted = false
        this.show_specialFeeEditModal = false
        this.isEditSFError = false
        this.editSFError = ""
      }
    },
}
</script>

<style>
  .rled {
    width: 10px;
    height: 10px;
    background: red;
    border-radius: 90px;
    filter: drop-shadow(0px 0px 25px #FD1800);
    display: inline-block;
  }

  .gled {
    width: 10px;
    height: 10px;
    background: green;
    border-radius: 90px;
    filter: drop-shadow(0px 0px 25px #40FD00);
    display: inline-block;
  }
</style>

<template>
    <Layout>
        <PageHeader :title="title" :items="items" />
        <div class="row">
            <div class="col-lg-6">
                <div class="card">
                    <div class="card-body">
                        <h4 class="card-title">Tarifa Vehiculos</h4>
                        <p class="card-title-desc"> Para clientes no recurrentes.</p>
                        <div>
                            <div class="mb-4">
                                <label for="parking_id">Parqueo Asignado</label>
                                <b-form-select 
                                    v-model="temp_assigned_parklot" 
                                    :options="parkingOptions"
                                    class="form-control"
                                    @change="getVisitorFee()">
                                </b-form-select>
                            </div>
                            <div class="mb-4">
                                <label>Duración gratuita</label>
                                <input id="free_duration" type="number" min=0 placeholder="minutos" class="form-control" v-model="selected_visitor_fee.free_duration">
                            </div>
                            <div class="mb-4">
                                <div class="row">
                                    <div class="col-lg-6">
                                        <label>Duración para tarifa inicial</label>
                                        <input id="initial_fee_duration" type="number" placeholder="minutos" class="form-control form-control" v-model="selected_visitor_fee.initial_fee_duration">
                                    </div>
                                    <div class="col-lg-6">
                                        <label>Costo de tarifa inicial</label>
                                        <input id="initial_fee" type="text" placeholder="Q" class="form-control form-control" v-model="selected_visitor_fee.initial_fee">
                                    </div>
                                </div>
                            </div>
                            <div class="mb-4">
                                <div class="row">
                                    <div class="col-lg-6">
                                        <label>Duración de tarifa recurrente</label>
                                        <input id="recurrent_fee_duration" type="number" placeholder="minutos" class="form-control form-control" v-model="selected_visitor_fee.repeat_fee_duration">
                                    </div>
                                    <div class="col-lg-6">
                                        <label>Costo de tarifa recurrente</label>
                                        <input id="recurrent_fee" type="text" placeholder="Q" class="form-control form-control" v-model="selected_visitor_fee.repeat_fee">
                                    </div>
                                </div>
                            </div>
                            <div class="mb-4">
                                <label>Tarifa Máxima Diaria</label>
                                <input id="max_daily_fee" type="number" min=0 placeholder="Q" class="form-control" v-model="selected_visitor_fee.max_fee">
                            </div>
                            <div class="mb-4">
                                <button class="btn btn-success" @click="saveVisitorFee">Guardar</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

          <div class="col-lg-6">
            <div class="card">
              <div class="card-body">
                <h4 class="card-title">Tarifa Motocicletas</h4>
                <p class="card-title-desc"> Para clientes no recurrentes.</p>
                <div>
                  <div class="mb-4">
                    <label for="parking_id">Parqueo Asignado</label>
                    <b-form-select
                        v-model="temp_m_assigned_parklot"
                        :options="parkingOptions"
                        class="form-control"
                        @change="getMotorcycleFee()">
                    </b-form-select>
                  </div>
                  <div class="mb-4">
                    <label>Duración gratuita</label>
                    <input id="free_duration" type="number" min=0 placeholder="minutos" class="form-control" v-model="selected_motorcycle_fee.free_duration">
                  </div>
                  <div class="mb-4">
                    <div class="row">
                      <div class="col-lg-6">
                        <label>Duración para tarifa inicial</label>
                        <input id="initial_fee_duration" type="number" placeholder="minutos" class="form-control form-control" v-model="selected_motorcycle_fee.initial_fee_duration">
                      </div>
                      <div class="col-lg-6">
                        <label>Costo de tarifa inicial</label>
                        <input id="initial_fee" type="text" placeholder="Q" class="form-control form-control" v-model="selected_motorcycle_fee.initial_fee">
                      </div>
                    </div>
                  </div>
                  <div class="mb-4">
                    <div class="row">
                      <div class="col-lg-6">
                        <label>Duración de tarifa recurrente</label>
                        <input id="recurrent_fee_duration" type="number" placeholder="minutos" class="form-control form-control" v-model="selected_motorcycle_fee.repeat_fee_duration">
                      </div>
                      <div class="col-lg-6">
                        <label>Costo de tarifa recurrente</label>
                        <input id="recurrent_fee" type="text" placeholder="Q" class="form-control form-control" v-model="selected_motorcycle_fee.repeat_fee">
                      </div>
                    </div>
                  </div>
                  <div class="mb-4">
                    <label>Tarifa Máxima Diaria</label>
                    <input id="max_daily_fee" type="number" min=0 placeholder="Q" class="form-control" v-model="selected_motorcycle_fee.max_fee">
                  </div>
                  <div class="mb-4">
                    <button class="btn btn-success" @click="saveMotorcycleFee">Guardar</button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-lg-6">
                <div class="card">
                    <div class="card-body">
                        <h4 class="card-title">Vehiculos Registrados</h4>
                        <p class="card-title-desc"> Para clientes recurrentes </p>
                        <div>
                             <div class="mb-4">
                                <label for="parking_id">Parqueo Asignado</label>
                                <b-form-select 
                                    v-model="selected_registered_fee.parking_id"
                                    :options="parkingOptions"
                                    class="form-control"
                                    @change="getRegisteredFee">
                                </b-form-select>
                            </div>
                            <div class="mb-4">
                                <label>Tipo</label>
                                <b-form-select 
                                    v-model="selected_registered_fee.type"
                                    :options="registered_vehicle_fee_types"
                                    class="form-control"
                                    @change="getRegisteredFee">
                                </b-form-select>
                            </div>
                            <div class="mb-4">
                                <label>Tarifa</label>
                                <input id="registered_fee" type="number" step="any" min=0 placeholder="Q" class="form-control" v-model="selected_registered_fee.fee">
                            </div>
                            <div class="mb-4">
                                <button class="btn btn-success" @click="saveRegisteredFee">Guardar</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

          <div class="col-lg-12">
            <div class="card">
              <div class="card-body">
                <h4 class="card-title">Tarifas Especiales</h4>
                <p class="card-title-desc">Tarifas sin control de acceso</p>
                <div>
                  <div class="mb-auto">
                    <button class="btn btn-success" @click="show_specialFeeModal = true">Crear Nueva</button>
                    <hr/>
                  </div>
                  <div class="mb-auto">
                    <table class="table table-centered datatable dt-responsive nowrap">
                      <thead class="thead-light">
                        <tr>
                          <th>Nombre</th>
                          <th>Parqueo</th>
                          <th>Tarifa (GTQ)</th>
                          <th>Código Tarifa</th>
                          <th style="width: 120px;">Acción</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(item, index) in special_fees" :key="index">
                          <td><div :class="item.active ? 'gled': 'rled'"></div> {{item.name}}</td>
                          <td>{{getParkingName(item.parking_id)}}</td>
                          <td>{{item.fee.toFixed(2)}}</td>
                          <td>{{item.type}}</td>
                          <td>
                            <a
                                @click="loadEditSpecialFee(item.parking_id, item._id)"
                                class="mr-3 text-primary"
                                v-b-tooltip.hover
                                title="Editar Información"
                            >
                              <i class="mdi mdi-pencil font-size-18"></i>
                            </a>
                            <a
                                @click="delSpecialFee(item._id, item.parking_id)"
                                class="text-danger"
                                v-b-tooltip.hover
                                title="Borrar"
                            >
                              <i class="mdi mdi-trash-can font-size-18"></i>
                            </a>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      <!-- CREATE SPECIAL FEE Modal -->
      <b-modal
          id="modal-1"
          v-model="show_specialFeeModal"
          title="Agregar Tarifa especial"
          title-class="text-dark font-18"
          hide-footer
          no-close-on-backdrop
          no-close-on-esc
          hide-header-close
      >
        <b-alert
            v-model="isCreateSFError"
            class="mt-3"
            variant="danger"
            dismissible>
          <span v-html="createSFError"></span>
        </b-alert>
        <form @submit.prevent="createSpecialFee">
          <div class="form-group">
            <label for="parking_id">Parqueo Asignado</label>
            <b-form-select
                v-model="special_fee.parking_id"
                :options="parkingOptions"
                :class="{ 'is-invalid': submitted && $v.special_fee.parking_id.$error }"></b-form-select>
            <div
                v-if="submitted && !$v.special_fee.parking_id.required"
                class="invalid-feedback"
            >El parqueo asignado es invalido</div>
          </div>

          <div class="form-group">
            <label for="password">Nombre</label>
            <input
                id="password"
                v-model="special_fee.name"
                type="text"
                class="form-control"
                placeholder="Nombre de la tarifa especial"
                :class="{ 'is-invalid': submitted && $v.special_fee.name.$error }"
            />
            <div
                v-if="submitted && !$v.special_fee.name.required"
                class="invalid-feedback"
            >El nombre de la tarifa es requerido</div>
          </div>
          <div class="form-group">
            <label for="password">Tarifa</label>
            <input
                id="password"
                v-model="special_fee.fee"
                type="number"
                step="any"
                class="form-control"
                placeholder="Valor de la tarifa especial"
                :class="{ 'is-invalid': submitted && $v.special_fee.fee.$error }"
            />
            <div
                v-if="submitted && !$v.special_fee.fee.required"
                class="invalid-feedback"
            >El Valor de la tarifa es requerido</div>
          </div>
          <div class="form-group">
            <label for="password">Activa</label>
            <input
                id="password"
                v-model="special_fee.active"
                type="checkbox"
                class="form-control"
                placeholder="Valor de la tarifa especial"
            />
          </div>
          <div class="text-right">
            <button type="submit" class="btn btn-success">Guardar</button>
            <b-button class="ml-1" variant="danger" @click="hideModal">Cancelar</b-button>
          </div>
        </form>
      </b-modal>
      <!-- end create modal -->

      <!-- EDIT SPECIAL FEE Modal -->
      <b-modal
          id="modal-1"
          v-model="show_specialFeeEditModal"
          title="Agregar Tarifa especial"
          title-class="text-dark font-18"
          hide-footer
          no-close-on-backdrop
          no-close-on-esc
          hide-header-close
      >
        <b-alert
            v-model="isEditSFError"
            class="mt-3"
            variant="danger"
            dismissible>
          <span v-html="editSFError"></span>
        </b-alert>
        <form @submit.prevent="editSpecialFee">
          <div class="form-group">
            <label for="parking_id">Parqueo Asignado</label>
            <b-form-select
                v-model="special_fee.parking_id"
                :options="parkingOptions"
                :class="{ 'is-invalid': submitted && $v.special_fee.parking_id.$error }"></b-form-select>
            <div
                v-if="submitted && !$v.special_fee.parking_id.required"
                class="invalid-feedback"
            >El parqueo asignado es invalido</div>
          </div>

          <div class="form-group">
            <label for="password">Nombre</label>
            <input
                id="password"
                v-model="special_fee.name"
                type="text"
                class="form-control"
                placeholder="Nombre de la tarifa especial"
                :class="{ 'is-invalid': submitted && $v.special_fee.name.$error }"
            />
            <div
                v-if="submitted && !$v.special_fee.name.required"
                class="invalid-feedback"
            >El nombre de la tarifa es requerido</div>
          </div>
          <div class="form-group">
            <label for="password">Tarifa</label>
            <input
                id="password"
                v-model="special_fee.fee"
                type="number"
                step="any"
                class="form-control"
                placeholder="Valor de la tarifa especial"
                :class="{ 'is-invalid': submitted && $v.special_fee.fee.$error }"
            />
            <div
                v-if="submitted && !$v.special_fee.fee.required"
                class="invalid-feedback"
            >El Valor de la tarifa es requerido</div>
          </div>
          <div class="form-group">
            <label for="password">Activa</label>
            <input
                id="password"
                v-model="special_fee.active"
                type="checkbox"
                class="form-control"
                placeholder="Valor de la tarifa especial"
            />
          </div>
          <div class="text-right">
            <button type="submit" class="btn btn-success">Guardar</button>
            <b-button class="ml-1" variant="danger" @click="hideEditSFModal">Cancelar</b-button>
          </div>
        </form>
      </b-modal>
      <!-- end create modal -->


    </Layout>
</template>